import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {ComponentContainer} from '../GeneralStyles/Component.style'
import {useTranslation} from 'react-i18next'
import {NewsRowWrapper, NewsWrapper, NoNewsMessage} from './News.style'
import {useNavigate} from 'react-router-dom'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import 'moment/locale/it'


const apiUrl = baseUrl + '/api/news?populate=*'
const News = () => {
    const {i18n, t} = useTranslation()
    const currentLocale = i18n.language
    const [data, setData] = useState(null)
    const [localizedData, setLocalizedData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    moment.locale(currentLocale)
    const generateSlug = (title) => {
        return title?.toLowerCase().replace(/\s+/g, '-').replace(/['"]/g, '-')
    }

    useEffect(() => {
        const apiUrlWithLocale = `${apiUrl}&locale=${currentLocale}`
        axios.get(apiUrlWithLocale)
            .then(response => {
                // Imposta i dati ricevuti
                setData(response.data.data)
                const localized = response.data.data.attributes.localizations.data.find(
                    (item) => item.attributes.locale === currentLocale
                )
                setLocalizedData(localized ? localized.attributes : null)
                setLoading(false)
            })
            .catch(err => {
                // Gestione degli errori
                setError(err)
                setLoading(false)
            })

    }, [currentLocale])


    return (
        !loading ? (
            <>
                <Helmet>
                    <title>{t('seoTitle.NewsSeo')}</title>
                    <meta name="description" content={t('metaDescription.NewsSeo')}/>
                </Helmet>
                <ComponentContainer>
                    <h1>{t('menu.News')}</h1>
                    {data?.length ? (
                        data.map((news) => {
                            const dataMoment = moment(news?.attributes?.date)
                            console.log(dataMoment.local().format('ddd DD-MM-YYYY HH:mm')
                        )
                            const slug = generateSlug(news.title)
                            return (
                                <NewsWrapper key={news.id}>
                                    <NewsRowWrapper onClick={() => navigate(`/news/${news.id}/${slug}`)}>
                                        <div>
                                            <p>{news?.attributes?.title}</p>
                                            <small>{t('news.Date')}: {dataMoment.local().format('ddd DD-MM-YYYY HH:mm')}</small>
                                        </div>
                                        <br/>
                                        {news?.attributes?.media?.data ? (
                                            <img
                                                src={news?.attributes?.media?.data?.[0]?.attributes?.formats?.thumbnail?.url}
                                                alt={news?.attributes?.title}
                                            />
                                        ) : null}
                                    </NewsRowWrapper>
                                </NewsWrapper>
                            )
                        })
                    ) : (
                        <NoNewsMessage>{t('news.NoNews')}</NoNewsMessage>
                    )}
                </ComponentContainer>
            </>
        ) : (
            <CircularLoader/>
        )
    )
}

export default News