import {styled} from '@mui/material/styles'

export const HomeBrandTitle = styled('div', {
    name: 'HomeBrand',
    slot: 'Title',
})(({theme}) => ({
    color: '#333',
    marginLeft: theme.spacing(4),
    textTransform: 'uppercase',
    cursor: 'pointer'
}))