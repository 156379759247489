import React from "react";
import { useTranslation } from "react-i18next";
import {Select, MenuItem} from '@mui/material'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import useBreakpoints from '../hooks/useBreakpoints'

const supportedLanguages = ['en', 'it', 'fr', 'es', 'de', 'nl', 'pt', 'cs', 'sv', 'pl', 'ja', 'hi'];

function LanguageSwitcher({setAnchorEl}) {
    const { i18n } = useTranslation();
    const {isMobile, isTabletPortrait} = useBreakpoints()

    const isMobileOrTabletPortrait = isMobile || isTabletPortrait

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };


    return (
        <Select style={{height: 30, marginTop: isMobileOrTabletPortrait ? 8 : 0, marginBottom: isMobileOrTabletPortrait ? 12 : 0, marginLeft: isMobileOrTabletPortrait ? 16 : 0}} value={supportedLanguages.includes(i18n.language) ? i18n.language : 'en'} onChange={handleChange}>
            <MenuItem value="it" title={'Italiano'} aria-label={'Cambia lingua in Italiano'} onClick={() => isMobileOrTabletPortrait ? setAnchorEl(null) : null}><span className="flag fi fi-it"></span></MenuItem>
            <MenuItem value="en" title={'English'} aria-label={'Switch language to English'} onClick={() => isMobileOrTabletPortrait ? setAnchorEl(null) : null}><span className="flag fi fi-gb"></span></MenuItem>
        </Select>
    );
}

export default LanguageSwitcher;
