import React, {useEffect, useState} from 'react'
import axios from 'axios'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import {baseUrl} from '../config'
import {SocialLink} from './Footer.style'
import Signature from './Signature'
import useBreakpoints from '../hooks/useBreakpoints'

const apiUrl = baseUrl + '/api/contact?populate=*'

const date = new Date() // Assuming the user wants to get the year from the current date
const year = date.getFullYear()

const Footer = ({isHomepage}) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const {isMobile, isTabletPortrait} = useBreakpoints()

    useEffect(() => {
        axios.get(apiUrl)
            .then(response => {
                // Imposta i dati ricevuti
                setData(response.data.data)
                setLoading(false)
                setError(false)
            })
            .catch(err => {
                // Gestione degli errori
                setError(err)
                setLoading(false)
            })

    }, [])

    return (
        <footer className={isHomepage ? 'homeFooter' : 'footer'}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <div>© {year > 2023 ? `2023-${year}` : year} - Beatrice Zappia</div>{'\u00A0'}-{'\u00A0'}{!isMobile && !isTabletPortrait && <small><i>Made by</i></small>}{'\u00A0'}
                <Signature isHomepage={isHomepage}/>
            </div>
            <div className={'socialIcons'}>
                {data?.attributes?.instagram &&
                    <SocialLink isHomepage={isHomepage} href={data?.attributes?.instagram} target={'_blank'}
                                rel="noopener noreferrer"><InstagramIcon/></SocialLink>}
                {data?.attributes?.facebook &&
                    <SocialLink isHomepage={isHomepage} href={data?.attributes?.facebook} target={'_blank'}
                                rel="noopener noreferrer"><FacebookIcon/></SocialLink>}
            </div>
        </footer>
    )
}

export default Footer