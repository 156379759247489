import {styled} from '@mui/material/styles'

export const SignatureContainer = styled('div', {
    name: 'Signature',
    slot: 'Container',
})(() => ({
    display: 'flex',
    width: 30,
    cursor: 'pointer',
    svg: {
        width: 30,
        height: 30
    }
}))