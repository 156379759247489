import {styled} from '@mui/material/styles'

export const NewsWrapper = styled('div', {
    name: 'News',
    slot: 'Wrapper',
})(({theme}) => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
        maxWidth: '90%',
      },

    [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
    },
}))

export const NewsRowWrapper = styled('div', {
    name: 'News',
    slot: 'RowWrapper',
})(({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #b2b2b2',
    borderRadius: 12,
    padding: theme.spacing(0, 0, 0, 2),
    marginBottom: theme.spacing(8),
    cursor: 'pointer',
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
        boxShadow: '0px 3px 2px rgba(0, 0, 0, 0.1)',
    },

    p: {
        fontSize: 18,
        lineHeight: 1,
        fontWeight: 'bold'
    },

    small: {
        display: 'inline-block',
        marginBottom: theme.spacing(2),
        color: '#a2a2a2',
        fontStyle: 'italic'
    },

    img: {
        height: 80,
        width: 'auto',
        borderRadius: 12,
    },

    [theme.breakpoints.up('sm')]: {

        img: {
            width: 100,
            height: 100,
        }
    },

    [theme.breakpoints.up('md')]: {
        img: {
            width: 120,
            height: 120,
        }
    },
}))

export const NoNewsMessage = styled('h3', {
    name: 'News',
    slot: 'Wrapper',
})(() => ({
    fontSize: 32,
    fontWeight: 'lighter',
    textAlign: 'center'
}))
