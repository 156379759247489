import { useMediaQuery, useTheme } from '@mui/material'

const useBreakpoints = () => {
    const theme = useTheme()
    return {
        isViewportWidthUnder426: useMediaQuery(theme.breakpoints.down(426)), // < 426
        isMobile: !useMediaQuery(theme.breakpoints.up('sm')), // < 600
        isTablet: useMediaQuery(theme.breakpoints.between('sm', 'lg')), // > 600 and < 1280
        isTabletPortrait: useMediaQuery(theme.breakpoints.between('sm', 'md')), // > 600 and < 1024
        isTabletLandscape: useMediaQuery(theme.breakpoints.between('md', 'lg')), // > 1024 and < 1280
        isDesktop: useMediaQuery(theme.breakpoints.up('lg')), // >= 1280
        isDesktopS: useMediaQuery(theme.breakpoints.between('lg', 'xl')), // > 1280 and < 1440
        isDesktopL: useMediaQuery(theme.breakpoints.up('xl')), // > 1440
    }
}

export default useBreakpoints
