import React from 'react'
import {Link} from 'react-router-dom'
import HomeBrand from './HomeBrand'
import Face4OutlinedIcon from '@mui/icons-material/Face4Outlined'
import {LoginIconWrapper, RightSideContainer} from './Navbar.style'
import LanguageSwitcher from './LanguageSwitcher'
import {useTranslation} from 'react-i18next'
import {baseUrl} from '../config'

const Navbar = () => {
    const loginUrl = baseUrl + '/admin/auth/login'
    const {t} = useTranslation()

    return (
        <>
            <nav className={'navbar'}>
                <HomeBrand/>
                <div className={'menu'}>
                    <Link to="/">
                        {t('menu.Home')}
                    </Link>
                    <Link to="/about">
                        {t('menu.About')}
                    </Link>
                    <Link to="/works">
                        {t('menu.Works')}
                    </Link>
                    <Link to="/news">
                        {t('menu.News')}
                    </Link>
                    <Link to="/contacts">
                        {t('menu.Contacts')}
                    </Link>
                </div>
                <RightSideContainer>
                    <LanguageSwitcher/>
                    <LoginIconWrapper onClick={() => window.open(loginUrl, '_blank')}>
                        <Face4OutlinedIcon/>
                    </LoginIconWrapper>
                </RightSideContainer>
            </nav>
        </>
    )
}

export default Navbar