import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import {
    GoBackIconWrapper,
    GoBackText,
    SingleWorkDetailsWrapper,
    SingleWorkImageWrapper,
    SingleWorkTitle,
    SingleWorkWrapper
} from './SingleWork.style'
import ReactMarkdown from 'react-markdown'
import {useTranslation} from 'react-i18next'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import {Helmet} from 'react-helmet'


const generateSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/['"]/g, '-')
}


const SingleWork = () => {
    const {id, slug} = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const apiUrl = `${baseUrl}/api/works/${id}?populate=*`
    const {t} = useTranslation()


    useEffect(() => {
        axios.get(apiUrl)
            .then(response => {
                setData(response.data.data)
                setIsDataLoaded(true)
                setLoading(false)

                const correctSlug = generateSlug(response.data.data.attributes.title)
                if (slug !== correctSlug) {
                    navigate(`/works/${id}/${correctSlug}`, {replace: true})
                }

            })
            .catch(error => {
                console.error('There was an error!', error)
            })
    }, [apiUrl, id, slug, navigate])

    if (!data) {
        return <CircularLoader/>
    }

    const image = data.attributes?.image?.data?.[0].attributes?.formats?.large?.url || data.attributes?.image?.data?.[0].attributes?.formats?.small?.url || data.attributes?.image?.data?.[0].attributes?.formats?.thumbnail?.url || ''
    const year = data.attributes?.year || ''
    const size = data.attributes?.size || ''
    const technique = data.attributes?.technique || ''
    const notes = data.attributes?.notes || ''
    const shareSlug = generateSlug(data.attributes.title)
    const pageToShare = `https://beatricezappia.it/works/${id}/${shareSlug}`

    return (
        <>
            {isDataLoaded && <Helmet>
                <title>{data.attributes.title} | {t('seoTitle.WorksSeoSingle')}</title>
                <meta name="description" content={t('metaDescription.WorksSeo')}/>

                <meta property="og:url" content={pageToShare}/>
                <meta property="og:title" content={data.attributes.title}/>
                <meta property="og:description" content={t('metaDescription.WorksSeo')}/>
                <meta property="og:type" content="website"/>
                {image && <meta property="og:image" content={`${image}?v=0`}/>}

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={data.attributes.title}/>
                <meta name="twitter:description" content={t('metaDescription.WorksSeo')}/>
                {image && <meta name="twitter:image" content={image}/>}
            </Helmet>}
            <SingleWorkWrapper>
                <SingleWorkTitle>{data.attributes.title}</SingleWorkTitle>
                <SingleWorkImageWrapper>
                    <img src={image} alt={`${data.attributes.title} by Beatrice Zappia`}/>
                </SingleWorkImageWrapper>
                <SingleWorkDetailsWrapper>
                    <span><strong>{t('works.Year')}</strong>: {year}</span>
                    <span><strong>{t('works.Size')}</strong>: {size}</span>
                    <span><strong>{t('works.Technique')}</strong>: {technique}</span>
                </SingleWorkDetailsWrapper>
                {notes && <ReactMarkdown>{notes}</ReactMarkdown>}
                <GoBackIconWrapper onClick={() => {
                    navigate('/works')
                }}>
                    <GoBackText>&#8592; {t('menu.Back')}</GoBackText>
                </GoBackIconWrapper>
            </SingleWorkWrapper>
        </>
    )
}

export default SingleWork