import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {ComponentContainer} from '../GeneralStyles/Component.style'
import {useTranslation} from 'react-i18next'
import {ContactsWrapper, SocialIconWrapper} from './Contacts.style'
import {Link} from 'react-router-dom'
import {Facebook, Instagram} from '@mui/icons-material'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import {Helmet} from 'react-helmet'

const apiUrl = baseUrl + '/api/contact?populate=*'
const Contacts = () => {
    const {i18n, t} = useTranslation()
    const currentLocale = i18n.language
    const [data, setData] = useState(null)
    const [localizedData, setLocalizedData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const regex = /.*\/([^\/]+)$/

    useEffect(() => {
        const apiUrlWithLocale = `${apiUrl}&locale=${currentLocale}`
        axios.get(apiUrlWithLocale)
            .then(response => {
                // Imposta i dati ricevuti
                setData(response.data.data)
                const localized = response.data.data.attributes.localizations.data.find(
                    (item) => item.attributes.locale === currentLocale
                )
                setLocalizedData(localized ? localized.attributes : null)
                setLoading(false)
                setError(false)
            })
            .catch(err => {
                // Gestione degli errori
                setError(err)
                setLoading(false)
            })

    }, [currentLocale])

    const instagramHandle = data?.attributes?.instagram.match(regex) || ''
    const facebookHandle = data?.attributes?.facebook.match(regex) || ''

    return (
        !loading ?
            <>
                <Helmet>
                    <title>{t('seoTitle.ContactsSeo')}</title>
                    <meta name="description" content={t('metaDescription.ContactsSeo')} />
                </Helmet>
                <ComponentContainer>
                    <h1>{data?.attributes?.title}</h1>
                    <ContactsWrapper>
                        {data?.attributes?.email && <p>Email: {data.attributes?.email}</p>}
                        {data?.attributes?.mobile && <p>Mobile: {data.attributes?.mobile}</p>}
                    </ContactsWrapper>
                    <ContactsWrapper>
                        <h2>{t('contacts.Socials')}</h2>
                        <SocialIconWrapper>
                            {data?.attributes?.instagram && <p><Instagram/> <Link to={data?.attributes?.instagram}
                                                                                  target={'_blank'}>@{instagramHandle[1] && instagramHandle[1]}</Link>
                            </p>}
                        </SocialIconWrapper>
                        <SocialIconWrapper>
                            {data?.attributes?.facebook && <p><Facebook/> <Link to={data?.attributes?.facebook}
                                                                                target={'_blank'}>@{facebookHandle[1] && facebookHandle[1]}</Link>
                            </p>}
                        </SocialIconWrapper>
                    </ContactsWrapper>
                </ComponentContainer>
            </>
            : <CircularLoader/>

    )
}

export default Contacts