import {styled} from '@mui/material/styles'

export const LoaderContainer = styled('div', {
    name: 'Loader',
    slot: 'Container',
})(() => ({
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const LoaderImage = styled('img', {
    name: 'Loader',
    slot: 'Image',
})(({ theme }) => ({
    width: 80,
    height: 80,
    opacity: 0.6,
    animation: 'spin 3s linear infinite',
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));
