import React from 'react'

const Faro = ({color = '#000000', title}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="464.000000pt" height="446.000000pt" viewBox="0 0 464.000000 446.000000"
             preserveAspectRatio="xMidYMid meet">
            {title && <title>{title}</title>}
            <g transform="translate(0.000000,446.000000) scale(0.100000,-0.100000)"
               fill={color} stroke="none">
                <path d="M2006 4139 l-118 -59 -49 50 c-36 37 -56 50 -77 50 -51 0 -69 -42
-32 -75 11 -10 20 -23 20 -30 0 -6 -22 -40 -50 -74 -27 -34 -50 -69 -50 -78 0
-26 52 -9 127 42 37 25 75 45 83 45 8 0 40 -24 70 -52 l54 -51 31 36 c28 32
119 202 127 236 6 26 -18 19 -136 -40z"/>
                <path d="M3090 3773 c-71 -31 -128 -82 -157 -141 -24 -48 -28 -69 -28 -137 0
-67 5 -88 27 -131 39 -75 116 -140 181 -154 104 -22 223 14 288 87 32 37 39
69 13 59 -50 -19 -111 -28 -169 -24 -85 5 -139 41 -177 117 -53 105 -24 229
69 294 23 17 43 34 43 39 0 13 -52 8 -90 -9z"/>
                <path d="M1517 3514 c-12 -12 -8 -119 5 -132 9 -9 7 -19 -11 -43 -20 -27 -22
-37 -15 -77 4 -26 8 -51 9 -56 0 -5 -21 -15 -47 -22 -113 -29 -197 -95 -246
-194 -27 -54 -27 -58 -32 -310 l-5 -255 -84 -3 c-59 -2 -88 -7 -95 -17 -6 -8
-29 -18 -51 -23 l-40 -8 0 -56 c0 -51 -4 -61 -32 -90 -59 -61 -50 -68 87 -69
66 -1 125 -3 130 -4 6 -1 17 -3 25 -4 11 -1 15 -13 15 -45 0 -85 -52 -630 -80
-836 -22 -156 -64 -390 -71 -390 -7 0 -204 177 -226 203 -130 157 -205 263
-274 392 -36 67 -67 93 -61 50 11 -72 105 -270 185 -390 107 -160 261 -330
398 -440 110 -88 193 -146 324 -224 274 -163 709 -309 1015 -341 152 -15 435
-13 557 6 165 24 355 98 499 194 87 58 156 119 148 131 -10 16 -270 139 -347
164 -139 45 -214 31 -289 -52 -83 -92 -156 -160 -202 -187 -62 -36 -150 -66
-196 -66 -150 0 -291 154 -348 380 -50 195 -109 651 -162 1240 -5 58 -12 129
-16 157 -7 60 3 71 50 53 17 -6 66 -8 127 -4 95 7 99 8 99 31 0 13 -6 23 -15
23 -11 0 -13 10 -9 48 5 41 2 52 -24 88 -41 56 -121 94 -196 94 -92 0 -86 -18
-86 239 0 208 -2 231 -22 289 -36 103 -113 177 -228 219 l-55 21 -3 58 c-2 46
-8 65 -27 84 -22 23 -24 31 -17 75 11 69 2 99 -29 103 -14 2 -28 0 -32 -4z
m188 -919 l0 -160 -155 0 -155 0 -3 140 c-4 205 -20 186 161 183 l152 -3 0
-160z m43 -802 l2 -182 -175 -3 c-96 -2 -180 -1 -185 0 -11 4 -14 354 -3 366
4 3 86 5 182 4 l176 -3 3 -182z"/>
                <path d="M980 2970 c-47 -5 -141 -17 -210 -29 -69 -11 -206 -32 -305 -46 -229
-33 -218 -29 -209 -68 3 -18 8 -34 10 -36 2 -2 92 10 201 28 465 74 654 108
665 119 6 6 8 18 5 26 -7 17 -18 18 -157 6z"/>
                <path d="M4115 2739 c-154 -26 -361 -59 -460 -74 -180 -26 -400 -60 -740 -115
-99 -16 -234 -37 -300 -46 -66 -9 -129 -20 -140 -25 -30 -13 105 -2 515 42
201 21 412 44 470 50 197 20 425 50 660 84 303 45 339 51 345 57 2 2 0 21 -6
41 -8 32 -14 37 -37 36 -15 -1 -153 -23 -307 -50z"/>
                <path d="M4420 1879 c-53 -13 -507 -104 -665 -133 -167 -30 -280 -50 -695
-121 -173 -30 -379 -65 -458 -79 -78 -15 -147 -26 -152 -26 -6 0 -10 -5 -10
-11 0 -11 55 -7 155 11 28 6 106 17 175 25 150 19 724 99 1160 161 300 43 534
85 546 97 8 7 -6 87 -14 86 -4 -1 -23 -5 -42 -10z"/>
            </g>
        </svg>
    )
}

export default Faro
