import React from 'react'
import {useTranslation} from 'react-i18next'
import {styled} from '@mui/material/styles'

const NotFoundContainer = styled('div', {
    name: 'NotFound',
    slot: 'Container',
})(() => ({
    width: '100vw',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}))

const NotFound = () => {
    const {t} = useTranslation()

    return (
        <NotFoundContainer>
            <h2>404: {t('notFound.Title')}</h2>
            <p>{t('notFound.Subtitle')}</p>
        </NotFoundContainer>
    )
}

export default NotFound