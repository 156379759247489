import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

const userLang = navigator.language || navigator.languages[0]
const mainLanguage = userLang.split('-')[0]

const resources = {
    'en': {
        translation: {
            menu: {
                'Home': 'Home',
                'About': 'About',
                'Works': 'Works',
                'News': 'News',
                'Contacts': 'Contacts',
                'Back': 'Back'
            },
            seoTitle: {
                'HPSeo': 'Figurative Painter | Original Works and Prints',
                'About': 'Who is Beatrice Zappia - Biography and Artistic Journey',
                'WorksSeo': 'Beatrice Zappia Art Gallery - Original Figurative Works',
                'WorksSeoSingle': 'Original Painting by Beatrice Zappia',
                'NewsSeo': 'Latest News on Beatrice Zappia - Art Updates and Events',
                'ContactsSeo': 'Contact Beatrice Zappia - Italian Figurative Painter'
            },
            metaDescription: {
                'HPSeo': "Beatrice Zappia, Painter, Architect, Feng Shui Consultant",
                'About': 'Learn about the biography and artistic journey of Beatrice Zappia, a figurative artist who has turned passion into a life\' s work.',
                'WorksSeo': "Visit Beatrice Zappia\\'s art gallery to view and purchase high-quality original figurative works.",
                'NewsSeo': 'Stay up-to-date with the latest news, updates, and artistic events involving Beatrice Zappia, a figurative painter.',
                'ContactsSeo': 'Want to know more about Beatrice Zappia? Contact her for information on works, prints, and upcoming exhibitions.'
            },
            works: {
                'clickOnTheImages': 'Click on the images',
                'Year': 'Year',
                'Size': 'Size',
                'Technique': 'Technique',
            },
            news: {
                'Date': 'Date',
                'NoNews': 'No news… so far!'
            },
            contacts: {
                'Socials': 'Socials',
            },
            whatsApp: {
                'helloThere': 'Hello there! 🤝 \nHow can I help?',
                'repliesWithinHour': 'Typically replies within 1 hour',
                'typeAMessage': 'Type a message…',
            },
            cookieStrip: {
                'NoCookiesText': 'This website does not collect or use any cookies. Please read our ',
                'NoCookiesLongText': 'This website does not collect or use any cookies. No personal data is collected from users through their navigation on this website. Should you have any questions or concerns about privacy, please contact us at beazappia@libero.it.',
                'Text': 'This website uses cookies to enhance the user experience.',
                'Decline': 'I decline',
                'OK': 'OK'
            },
            notFound: {
                'Title': 'Page Not Found',
                'Subtitle': 'Try with another URL!'
            }
        },
    },
    'it': {
        translation: {
            menu: {
                'Home': 'Home',
                'About': 'Bio',
                'Works': 'Opere',
                'News': 'News',
                'Contacts': 'Contatti',
                'Back': 'Indietro'
            },
            seoTitle: {
                'HPSeo': 'Pittrice Figurativa | Opere Originali e Stampe',
                'About': 'Chi è Beatrice Zappia - Biografia e Percorso Artistico',
                'WorksSeo': 'Opere di Beatrice Zappia - Opere Figurative Originali',
                'WorksSeoSingle': 'Dipinto Originale di Beatrice Zappia',
                'NewsSeo': 'Ultime Notizie su Beatrice Zappia - Novità e Eventi Artistici',
                'ContactsSeo': 'Contatta Beatrice Zappia - Pittrice Figurativa Italiana'
            },
            metaDescription: {
                'HPSeo': "Beatrice Zappia, Pittrice, Architetto, Feng Shui Consultant",
                'About': 'Scopri la biografia e il percorso artistico di Beatrice Zappia, un\'artista figurativa che ha fatto della passione la sua vita.',
                'WorksSeo': "Visita la galleria d\\'arte di Beatrice Zappia per ammirare e acquistare opere figurative originali di alta qualità.",
                'NewsSeo': 'Resta aggiornato sulle ultime notizie, novità ed eventi artistici riguardanti Beatrice Zappia, pittrice figurativa.',
                'ContactsSeo': 'Vuoi saperne di più su Beatrice Zappia? Contattala per informazioni su opere, stampe e future esposizioni.'
            },
            works: {
                'clickOnTheImages': 'Clicca sulle immagini',
                'Year': 'Anno',
                'Size': 'Dimensioni',
                'Technique': 'Tecnica',
            },
            news: {
                'Date': 'Data',
                'NoNews': 'Non ci sono news… per il momento!'
            },
            contacts: {
                'Socials': 'Social',
            },
            whatsApp: {
                'helloThere': 'Ciao! 🤝 \nPosso aiutarti?',
                'repliesWithinHour': 'Solitamente risponde entro 1 ora',
                'typeAMessage': 'Scrivi un messaggio...',
            },
            cookieStrip: {
                'NoCookiesText': 'Questo sito web non raccoglie o utilizza alcun cookie. Leggi la ',
                'NoCookiesLongText': 'Il presente sito web non raccoglie o utilizza alcun cookie. Non vengono raccolti dati personali degli utenti attraverso la navigazione su questo sito web. Per qualsiasi domanda o preoccupazione riguardante la privacy, si prega di contattarci all\'indirizzo email beazappia@libero.it.',
                'Text': 'Questo sito web utilizza i cookie per migliorare l\'esperienza dell\'utente.',
                'Decline': 'Non accetto',
                'OK': 'OK'
            },
            notFound: {
                'Title': 'Pagina non trovata',
                'Subtitle': 'Prova con un altro indirizzo!'
            }
        },
    },

}

i18n.use(initReactI18next).init({
    resources,
    lng: mainLanguage,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
})

export default i18n