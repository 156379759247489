import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {Menu as HamburgerMenu} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import HomeBrand from './HomeBrand'
import {Divider} from '@mui/material'
import Face4OutlinedIcon from '@mui/icons-material/Face4Outlined'
import {LoginIconWrapper} from './Navbar.style'
import {useTranslation} from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'
import {baseUrl} from '../config'

export default function MobileMenu() {
    const loginUrl = baseUrl + '/admin/auth/login'
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const {t} = useTranslation()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogin = () => {
        setAnchorEl(null)
        window.open(loginUrl, '_blank')
    }

    return (
        <div className={'hamburgerMenu'}>
            <HomeBrand />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <HamburgerMenu />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '100vw',
                        marginTop: 6
                    },
                }}
            >
                <MenuItem className={'hamburgerMenuItem'} onClick={handleClose}>
                    <Link to="/" style={{padding: 5}}>
                        {t('menu.Home')}
                    </Link>
                </MenuItem>
                <MenuItem className={'hamburgerMenuItem'} onClick={handleClose}>
                    <Link to="/about" style={{padding: 5}}>
                        {t('menu.About')}
                    </Link>
                </MenuItem>
                <MenuItem className={'hamburgerMenuItem'} onClick={handleClose}>
                    <Link to="/works" style={{padding: 5}}>
                        {t('menu.Works')}
                    </Link>
                </MenuItem>
                <MenuItem className={'hamburgerMenuItem'} onClick={handleClose}>
                    <Link to="/news" style={{padding: 5}}>
                        {t('menu.News')}
                    </Link>
                </MenuItem>
                <MenuItem className={'hamburgerMenuItem'} onClick={handleClose}>
                    <Link to="/contacts" style={{padding: 5}}>
                        {t('menu.Contacts')}
                    </Link>
                </MenuItem>
                <Divider />
                <LanguageSwitcher setAnchorEl={setAnchorEl} />
                <Divider />
                <LoginIconWrapper onClick={handleLogin}>
                    <Face4OutlinedIcon htmlColor={'#333'} />
                </LoginIconWrapper>
            </Menu>
        </div>
    )
}