import React, {useEffect, useState} from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import {Link} from 'react-router-dom'
import {HPTextWrapper, SentenceAuthor, SentenceWrapper} from './Home.style'
import {useTranslation} from 'react-i18next'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import { Helmet } from 'react-helmet'

const apiUrl = baseUrl + '/api/homepage?populate=*'

const Home = () => {
    const {i18n, t} = useTranslation()
    const currentLocale = i18n.language
    const [data, setData] = useState(null)
    const [localizedData, setLocalizedData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    useEffect(() => {
        const apiUrlWithLocale = `${apiUrl}&locale=${currentLocale}`
        axios.get(apiUrlWithLocale)
            .then(response => {
                // Imposta i dati ricevuti
                setData(response.data.data)
                const localized = response.data.data.attributes.localizations.data.find(
                    (item) => item.attributes.locale === currentLocale
                )
                setLocalizedData(localized ? localized.attributes : null)
                setLoading(false)
            })
            .catch(err => {
                // Gestione degli errori
                setError(err)
                setLoading(false)
            })

    }, [currentLocale])

    return (
        !loading ?
            <>
                <Helmet>
                    <title>Beatrice Zappia | {t('seoTitle.HPSeo')}</title>
                    <meta name="description" content={t('metaDescription.HPSeo')} />
                </Helmet>
                <div className="App">
                    <div className="image-container">
                        <div>
                            <img
                                src={data?.attributes?.picture?.data?.attributes?.url}
                                alt={data?.attributes?.name}
                                className="fullscreen-image"/>
                        </div>
                        <div className="flex-container">
                            <HPTextWrapper>
                                <div className="centered-text">
                                    <Link to="/works">
                                        <h1 className={'name'}>{data?.attributes?.name}</h1>
                                    </Link>
                                </div>
                                <SentenceWrapper>
                                    <ReactMarkdown>{data?.attributes?.sentence}</ReactMarkdown>
                                    <SentenceAuthor>{data?.attributes?.author}</SentenceAuthor>
                                </SentenceWrapper>
                            </HPTextWrapper>
                        </div>
                    </div>
                </div>
            </>
            : <CircularLoader/>
    )
}

export default Home