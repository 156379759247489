import {styled} from '@mui/material/styles'

export const WorksContainer = styled('div', {
    name: 'Works',
    slot: 'Container',
})(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(.75),

    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: theme.spacing(2),
    },
}))