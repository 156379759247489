import './App.css'
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom'
import Home from './pages/Home/Home'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Works from './pages/Works/Works'
import News from './pages/News/News'
import NotFound from './pages/NotFound'
import React, {useContext, useEffect, useState, useTransition} from 'react'
import About from './pages/About/About'
import Contacts from './pages/Contacts/Contacts'
import useBreakpoints from './hooks/useBreakpoints'
import MobileMenu from './components/MobileMenu'
import {LocationContext} from './LocationContext'
import SingleWork from './pages/Works/SingleWork'
import Beatrice from './images/BeatriceWhatsAppAvatar.jpeg'
import {FloatingWhatsApp} from 'react-floating-whatsapp'
import {useTranslation} from 'react-i18next'
import SingleNews from './pages/News/SingleNews'
import {CookieConsent} from 'react-cookie-consent'
import ScrollToTop from './components/ScrollToTop'
import Privacy from './pages/Privacy/Privacy'


function MainContent({isWorksPage}) {
    const location = useLocation()
    const setLocationState = useContext(LocationContext)

    useEffect(() => {
        const isHomePage = location.pathname === '/'
        const isWorksPage = location.pathname === '/works'
        const isContactsPage = location.pathname === '/contacts'
        setLocationState({isHomePage, isWorksPage, isContactsPage})
    }, [location, setLocationState])

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/works" element={<Works isWorksPage={isWorksPage}/>}/>
            <Route path="/works/:id/:slug" element={<SingleWork/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/news/:id/:slug" element={<SingleNews/>}/>
            <Route path="/contacts" element={<Contacts/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}


function App() {
    const {isMobile, isTabletPortrait} = useBreakpoints()
    const [locationState, setLocationState] = useState({})
    const {isHomePage, isWorksPage, isContactsPage} = locationState
    const {t} = useTranslation()

    return (
        <LocationContext.Provider value={setLocationState}>
            <CookieConsent
                location="bottom"
                buttonText={t('cookieStrip.OK')}
                declineButtonText={t('cookieStrip.Decline')}
                // cookieName="myAwesomeCookieName2"
                style={{background: '#2B373B'}}
                buttonStyle={{color: '#4e503b', fontSize: 13}}
                expires={150}
                // enableDeclineButton
            >
                {t('cookieStrip.NoCookiesText')} <a style={{color: 'yellow', textDecoration: 'none'}} href="/privacy">privacy policy</a>.
            </CookieConsent>
            <div className="app">
                <div className="appWrapper">
                    <Router>
                        <ScrollToTop />
                        {!!isMobile || !!isTabletPortrait ? <MobileMenu/> : <Navbar/>}
                        <MainContent isWorksPage={isWorksPage} isContactsPage={isContactsPage} />
                    </Router>
                </div>
                <Footer isHomepage={isHomePage}/>
            </div>
            <FloatingWhatsApp
                phoneNumber="+393201640718"
                accountName={'Beatrice'}
                statusMessage={t('whatsApp.repliesWithinHour')}
                chatMessage={t('whatsApp.helloThere')}
                placeholder={t('whatsApp.typeAMessage')}
                buttonStyle={{transform: 'scale(0.8)', bottom: 70, right: isMobile ? 10 : 50}}
                chatboxStyle={{bottom: 130}}
                avatar={Beatrice}
                messageDelay={1}
                allowClickAway
                allowEsc
            />
        </LocationContext.Provider>
    )
}

export default App
