import React from 'react'
import {HomeBrandTitle} from './HomeBrand.style'
import {useNavigate} from 'react-router-dom'

const HomeBrand = () => {
    const navigate = useNavigate()

    return (
        <HomeBrandTitle onClick={() => navigate('/')}>
            Beatrice Zappia
        </HomeBrandTitle>
    )
}

export default HomeBrand