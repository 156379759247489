import React from 'react';
import {SignatureContainer} from './Signature.style'
import FaroIcon from './FaroIcon'

const Signature = ({isHomepage}) => {
    const faroUrl = 'https://www.linkedin.com/in/stefanoesposito75/'
    return (
        <SignatureContainer onClick={() => window.open(faroUrl, '_blank')}>
            <FaroIcon color={isHomepage ? '#333' : 'white'} title={'ilFaro'} />
        </SignatureContainer>
    );
}

export default Signature