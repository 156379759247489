import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import axios from 'axios'
import {baseUrl} from '../../config'
import {
    GoBackIconWrapper, GoBackText,
    SingleNewsDetailsWrapper,
    SingleNewsImageWrapper,
    SingleNewsTitle,
    SingleNewsWrapper,
} from './SingleNews.style'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import 'moment/locale/it'
import {Helmet} from 'react-helmet'


const generateSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/['"]/g, '-')
}
const SingleNews = () => {
    const {id, slug} = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [localizedData, setLocalizedData] = useState(null)
    const [loading, setLoading] = useState(true)
    const apiUrl = `${baseUrl}/api/news/${id}?populate=*`
    const {i18n,t} = useTranslation()
    const currentLocale = i18n.language
    useEffect(() => {
        const apiUrlWithLocale = `${apiUrl}&locale=${currentLocale}`
        axios.get(apiUrlWithLocale)
            .then(response => {
                setData(response.data.data)
                const localized = response.data.data.attributes.localizations.data.find(
                    (item) => item.attributes.locale === currentLocale
                )
                setLocalizedData(localized ? localized.attributes : null)
                setLoading(false)

                const correctSlug = generateSlug(response.data.data.attributes.title)
                if (slug !== correctSlug) {
                    navigate(`/news/${id}/${correctSlug}`, {replace: true})
                }

            })
            .catch(error => {
                console.error('There was an error!', error)
            })
    }, [apiUrl, id, slug, navigate])

    if (!data) {
        return <div>Loading...</div>
    }

    const image = data.attributes?.media?.data?.[0].attributes?.formats?.large?.url || data.attributes?.media?.data?.[0].attributes?.formats?.small?.url || data.attributes?.media?.data?.[0].attributes?.formats?.thumbnail?.url || ''
    const date = data.attributes?.date || ''
    const dataMoment = moment(date)
    const text = data.attributes?.text || ''
    return (
        <>
            <Helmet>
                <title>{data.attributes.title} | Beatrice Zappia</title>
                <meta name="description" content={t('metaDescription.NewsSeo')}/>
            </Helmet>
            <SingleNewsWrapper>
                <SingleNewsTitle>{data.attributes.title}</SingleNewsTitle>
                <SingleNewsImageWrapper>
                    <img src={image} alt={`${data.attributes.title} by Beatrice Zappia`}/>
                </SingleNewsImageWrapper>
                <SingleNewsDetailsWrapper>
                    <small>{t('news.Date')}: {dataMoment.local().format('ddd DD-MM-YYYY HH:mm')}</small>

                    {text && <ReactMarkdown>{text}</ReactMarkdown>}
                </SingleNewsDetailsWrapper>
                <GoBackIconWrapper onClick={() => {
                    navigate('/news')
                }}>
                    <GoBackText>&#8592; {t('menu.Back')}</GoBackText>
                </GoBackIconWrapper>
            </SingleNewsWrapper>
        </>

    )
}

export default SingleNews