import {styled} from '@mui/material/styles'

export const PrivacyTextWrapper = styled('div', {
    name: 'Privacy',
    slot: 'TextWrapper',
})(({theme}) => ({
    margin: theme.spacing(0, 2),

    p: {
        lineHeight: 1.5,
    },

    [theme.breakpoints.up('md')]: {
        margin: 0
    },
}))