import {styled} from '@mui/material/styles'

export const ComponentContainer = styled('div', {
    name: 'Component',
    slot: 'Container',
    shouldForwardProp: (prop) => prop !== 'isWorksPage' && prop !== 'isContactsPage'
})(({theme, isWorksPage, isContactsPage}) => ({
    padding: theme.spacing(10, isWorksPage ? 0.75 : 3),

    '& > h1': {
        fontWeight: 'lighter',
        textAlign: 'center'
    },

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(18, (isWorksPage || isContactsPage) ? 24 : 84),
    },
}))