import {styled} from '@mui/material/styles'

export const LoginIconWrapper = styled('div', {
    name: 'Navbar',
    slot: 'LoginIconWrapper',
})(({theme}) => ({
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(4),
    padding: theme.spacing(4,0),
    svg: {
      fill: '#8b8b8b'
    },

    [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(4),
        padding: 0
    },
}))

export const RightSideContainer = styled('div', {
    name: 'Navbar',
    slot: 'RightSideContainer',
})(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
}))