import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {ComponentContainer} from '../GeneralStyles/Component.style'
import {useNavigate} from 'react-router-dom'
import {WorksContainer} from './Works.style'
import {useTranslation} from 'react-i18next'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import {Helmet} from 'react-helmet'

const apiUrl = baseUrl + '/api/works?populate=*&sort=rank:asc'
const Works = ({isWorksPage}) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const generateSlug = (title) => {
        return title?.toLowerCase().replace(/\s+/g, '-').replace(/['"]/g, '-')
    }

    useEffect(() => {
        axios.get(apiUrl)
            .then(response => {
                // Imposta i dati ricevuti
                setData(response.data.data)
                setLoading(false)
            })
            .catch(err => {
                // Gestione degli errori
                setError(err)
                setLoading(false)
            })

    }, [])


    return (
        !loading ?
            <>
                <Helmet>
                    <title>{t('seoTitle.WorksSeo')}</title>
                    <meta name="description" content={t('metaDescription.WorksSeo')} />
                </Helmet>
                <ComponentContainer isWorksPage={isWorksPage}>
                    <h1>{t('menu.Works')}</h1>
                    <p style={{textAlign: 'center', fontWeight: 'lighter'}}><i>{t('works.clickOnTheImages')}</i></p>
                    <WorksContainer>
                        {data?.map((work) => {
                            const slug = generateSlug(work.title)
                            return (
                                <div key={work.id} className={'grid-item'}
                                     onClick={() => navigate(`/works/${work.id}/${slug}`)}>
                                    <img src={work?.attributes?.image?.data?.[0]?.attributes?.formats?.small?.url}
                                         alt={work?.attributes?.title}/>
                                    <div className="overlay">
                                        <div className="overlay-text">{work?.attributes?.title}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </WorksContainer>
                </ComponentContainer>
            </>
            : <CircularLoader/>

    )
}

export default Works