import {styled} from '@mui/material/styles'

export const HPTextWrapper = styled('div', {
    name: 'HPText',
    slot: 'Wrapper',
})(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 4, 0),
    margin: theme.spacing(0, 8),
    borderRadius: 16,
    overflow: 'hidden',
    // gap: theme.spacing(8),
    position: 'relative',
    zIndex: 3,

    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },

    [theme.breakpoints.up('sm')]: {
        maxWidth: 400
    },

    [theme.breakpoints.up('md')]: {
        maxWidth: '100vw',
        width: '100%',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-evenly',
        backdropFilter: 'blur(6px)',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0.4), rgba(255,255,255,0.4))',
    },
}))

export const SentenceWrapper = styled('div', {
    name: 'Sentence',
    slot: 'Wrapper',
})(({theme}) => ({
    width: '60%',
    fontFamily: '\'TimesNewRoman\'',
    color: 'white',
    fontSize: 14,
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(3),
    position: 'relative',
    textAlign: 'left',
    marginLeft: theme.spacing(30),

    [theme.breakpoints.up('md')]: {
        color: '#333',
        marginTop: 0,
        maxWidth: 400,
        lineHeight: 1.2,
        fontSize: 17,
        padding: 0,
        marginRight: theme.spacing(4),
        marginLeft: 'initial',

        p: {
            borderLeft: '3px solid #333',
            paddingLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        }
    },
}))

export const SentenceAuthor = styled('span', {
    name: 'Sentence',
    slot: 'Author',
})(({theme}) => ({
    color: 'white',
    fontFamily: '\'Arial\', sans-serif',
    fontSize: 12,
    fontStyle: 'italic',

    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 4,
        bottom: 8,
        color: '#333',

    },
}))