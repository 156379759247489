import {styled} from '@mui/material/styles'

export const ContactsWrapper = styled('div', {
    name: 'Contacts',
    slot: 'Wrapper',
})(({theme}) => ({
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #b2b2b2',
    borderRadius: 12,
    padding: theme.spacing(0, 0, 0, 2),
    marginBottom: theme.spacing(8),
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
        boxShadow: '0px 3px 2px rgba(0, 0, 0, 0.1)',
    },

    p: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        fontSize: 18,
        lineHeight: 1,
        fontStyle: 'italic'
    },
}))

export const SocialIconWrapper = styled('div', {
    name: 'Contacts',
    slot: 'SocialIconWrapper',
})(({theme}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    a: {
        textDecoration: 'none',
        color: '#333',

        '&:hover': {
            color: '#2e2e2e',
        }
    }
}))
