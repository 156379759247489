import React from 'react';
import YinYang from '../assets/yinyang.svg';
import {LoaderContainer, LoaderImage} from './Loader.style'  // Importa il file SVG

const CircularLoader = () => {
    return (
        <LoaderContainer>
            <LoaderImage src={YinYang} className="loader" alt="loading" />
        </LoaderContainer>
    );
};

export default CircularLoader;
