import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {ComponentContainer} from '../GeneralStyles/Component.style'
import ReactMarkdown from 'react-markdown'
import {PrivacyTextWrapper} from './Privacy.style'
import {useTranslation} from 'react-i18next'
import {baseUrl} from '../../config'
import CircularLoader from '../../components/Loader'
import {Helmet} from 'react-helmet'

const apiUrl = baseUrl + '/api/about?populate=*'
const Privacy = () => {
    const {i18n, t} = useTranslation()
    const currentLocale = i18n.language
    const [data, setData] = useState(null)
    const [localizedData, setLocalizedData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const apiUrlWithLocale = `${apiUrl}&locale=${currentLocale}`

        axios.get(apiUrlWithLocale)
            .then(response => {
                // Set the received data
                setData(response.data.data)
                const localized = response.data.data.attributes.localizations.data.find(
                    (item) => item.attributes.locale === currentLocale
                )
                setLocalizedData(localized ? localized.attributes : null)
                setLoading(false)
                // console.log('Response data:', response.data)
            })
            .catch(err => {
                // Handle errors
                setError(err)
                setLoading(false)
            })
    }, [currentLocale]) // Add currentLocale as a dependency


    return (
        !loading ?
            <>
                <Helmet>
                    <title>Privacy Policy | Beatrice Zappia</title>
                    <meta name="description" content={t('cookieStrip.NoCookiesText')} />
                </Helmet>
                <ComponentContainer>
                    <h1>Privacy Policy</h1>
                    <PrivacyTextWrapper>
                        <p>{t('cookieStrip.NoCookiesLongText')}</p>
                    </PrivacyTextWrapper>
                </ComponentContainer>
            </>
            : <CircularLoader/>
    )
}

export default Privacy