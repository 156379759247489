import {styled} from '@mui/material/styles'

export const SingleWorkWrapper = styled('div', {
    name: 'SingleWork',
    slot: 'Wrapper',
})(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(10, 4),

    [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(24, 8),
    },

    [theme.breakpoints.up('md')]: {
        width: '60%',
        margin: '80px auto'
    },
}))
export const SingleWorkImageWrapper = styled('div', {
    name: 'SingleWork',
    slot: 'ImageWrapper',
})(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),

    img: {
        width: '100%'
    },
}))

export const SingleWorkDetailsWrapper = styled('div', {
    name: 'SingleWork',
    slot: 'DetailsWrapper',
})(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: theme.spacing(6),
    },
}))


export const SingleWorkTitle = styled('h1', {
    name: 'SingleWork',
    slot: 'Title',
})(({theme}) => ({
    textAlign: 'center',
    fontWeight: 'lighter',
    marginTop: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
        fontSize: 42,
    },

    [theme.breakpoints.up('md')]: {
        fontSize: 48,
    },
}))

export const GoBackIconWrapper = styled('div', {
    name: 'SingleWork',
    slot: 'IconWrapper',
})(({theme}) => ({
    display: 'flex',
    paddingTop: 40,
    cursor: 'pointer',
    fontWeight: 'lighter',
    marginBottom: theme.spacing(2)
}))

export const GoBackText = styled('span', {
    name: 'SingleWork',
    slot: 'GoBackText',
})(() => ({
    fontSize: 16,
    fontWeight: 'lighter',
    fontStyle: 'italic'
}))