import {styled} from '@mui/material/styles'

export const ImageWrapper = styled('div', {
    name: 'About',
    slot: 'ImageWrapper',
})(({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    img: {
        maxWidth: '60%',
        borderRadius: 16,

        [theme.breakpoints.up('md')]: {
            maxWidth: 250,
        },
    }
}))

export const AboutTextWrapper = styled('div', {
    name: 'About',
    slot: 'AboutTextWrapper',
})(({theme}) => ({
    margin: theme.spacing(0, 2),

    p: {
      lineHeight: 1.3,
    },

    [theme.breakpoints.up('md')]: {
        margin: 0
    },
}))